<template>
  <div class="overviewBg">
    <!-- <div v-if="currentWorkspace?.connected"> -->
    <v-row class="align-center pb-5 pt-3 mx-3">
      <v-col cols="2">
        <h3>Overview</h3>
      </v-col>
      <v-col cols="8">
        <!-- <v-card height="44px" width="551px" class="rounded-lg"> -->
        <!-- for future use -->
        <!-- <v-tabs
          hide-slider
          :v-model="tab"
          align-tabs="center"
          active-class="selectedTabItem"
          centered
          style="
            width: 445px !important;
            border-radius: 8px !important;
            height: 44px !important;
          "
        >
          <v-tab
            v-for="(tab, index) in overviewTabs"
            :key="index"
            class="f-inter fs-14 fw-400 text-none veryDarkPurple--text"
          >
            {{ tab.name }}</v-tab
          >
        </v-tabs> -->
        <!-- for future use end -->

        <!-- </v-card> -->
      </v-col>
      <v-col cols="2" class="text-end">
        <!-- <v-date-picker v-model="selectedDate" ref="datePicker"> </v-date-picker> -->
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="167px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateRangeText"
              label="Date"
              readonly
              v-bind="attrs"
              v-on="on"
              outlined
              append-icon="mdi-chevron-down"
              dense
              hide-details
              class="white rounded-lg"
            ></v-text-field>
          </template>
          <v-date-picker v-model="dates" range scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="resetDateAndCloseMenu()">
              Cancel
            </v-btn>
            <v-btn text color="primary" @click="getBankDetails()"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-divider color="greyDivider--text"></v-divider>
    <v-window v-model="tab">
      <v-window-item>
        <v-container fluid>
          <v-row class="mx-1">
            <!-- **** -->
            <!-- Left section -->
            <v-col :cols="$vuetify.breakpoint.lg ? 8 : 12">
              <p class="f-inter fs=18 fw-600 mb-1">AR/AP</p>
              <!-- ** -->
              <!-- Left section upper two cards -->
              <v-row>
                <v-col :cols="$vuetify.breakpoint.lg ? 6 : 12">
                  <v-skeleton-loader
                    v-if="!receivableStatus.length"
                    type="article"
                  ></v-skeleton-loader>
                  <!-- First card -->
                  <v-card
                    :width="$vuetify.breakpoint.lg ? '470px' : '348px'"
                    v-if="receivableStatus.length"
                    class="elevation-0"
                    style="border-radius: 6px !important"
                  >
                    <v-card-title class="fs-14 fw-400 f-inter pt-1 pb-1 pl-2"
                      >Total Receivables</v-card-title
                    >
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                    <!-- First card status section -->
                    <v-row class="pt-4 pb-3">
                      <v-col
                        v-for="(status, index) in receivableStatus"
                        :key="index + 'A'"
                        cols="6"
                        class="fs-12 fw-400 f-inter px-7"
                        :class="
                          status.name == 'Draft'
                            ? 'draftBlue--text'
                            : status.name == 'Overdue'
                            ? 'overdueRed--text'
                            : status.name == 'Paid'
                            ? 'paidGreen--text'
                            : 'totalBlack--text'
                        "
                      >
                        {{ status.proportion.toLocaleString() }}
                        {{ status.name }}<br />
                        <span class="halfDark--text f-inter fs-16 fw-600"
                          >${{ status.value.toLocaleString() }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                    <v-card-subtitle
                      class="f-inter fs-10 fw-400 unpaidGrey--text pb-1 pt-1 pl-2"
                      >Total unpaid invoices</v-card-subtitle
                    >
                    <!-- Progress bar -->
                    <v-card-text class="pl-2 pr-2">
                      <v-progress-linear
                        :value="totalReceivablesValue"
                        color="paidGreen"
                        class="rounded-lg"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.lg ? 6 : 12">
                  <v-skeleton-loader
                    v-if="!payableStatus.length"
                    type="article"
                  ></v-skeleton-loader>

                  <!-- Second card -->
                  <v-card
                    :width="$vuetify.breakpoint.lg ? '470px' : '348px'"
                    v-if="payableStatus.length"
                    class="elevation-0"
                    style="border-radius: 6px !important"
                  >
                    <v-card-title class="fs-14 fw-400 f-inter pt-1 pb-1 pl-2"
                      >Total Payables</v-card-title
                    >
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                    <!-- Second card status section -->
                    <v-row class="pt-4 pb-3">
                      <v-col
                        v-for="(status, index) in payableStatus"
                        :key="index + 'B'"
                        cols="6"
                        class="fs-12 fw-400 f-inter px-7"
                        :class="
                          status.name == 'Draft'
                            ? 'draftBlue--text'
                            : status.name == 'Overdue'
                            ? 'overdueRed--text'
                            : status.name == 'Paid'
                            ? 'paidGreen--text'
                            : 'totalBlack--text'
                        "
                      >
                        {{ status.proportion.toLocaleString() }}
                        {{ status.name }}<br />
                        <span class="halfDark--text f-inter fs-16 fw-600"
                          >${{ status.value.toLocaleString() }}</span
                        >
                      </v-col>
                    </v-row>
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                    <v-card-subtitle
                      class="f-inter fs-10 fw-400 unpaidGrey--text pb-1 pt-1 pl-2"
                      >Total unpaid invoices</v-card-subtitle
                    >
                    <!-- Progess bar -->
                    <v-card-text class="pl-2 pr-2">
                      <v-progress-linear
                        :value="totalPayableValue"
                        color="overdueRed"
                        bg-color="unpaidGrey"
                        class="rounded-lg"
                      ></v-progress-linear>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
              <!-- ** -->
              <!-- Left section lower two cards -->
              <v-row>
                <!-- Client details -->
                <v-col :cols="$vuetify.breakpoint.lg ? 6 : 12">
                  <v-card
                    :width="$vuetify.breakpoint.lg ? '470px' : '348px'"
                    height="330px"
                    class="elevation-0"
                    style="border-radius: 6px !important"
                  >
                    <v-card-title
                      class="justify-space-between f-inter fs-14 fw-600 pt-2 pb-2"
                    >
                      <p class="mb-0">Client</p>
                      <p class="mb-0">Amount</p>
                    </v-card-title>
                    <v-divider
                      class="mx-1 mb-3"
                      color="greyDivider--text"
                    ></v-divider>

                    <div style="height: 215px">
                      <v-card-text
                        v-for="(client, index) in clients"
                        :key="index + 'C'"
                        class="justify-space-between overflow-hidden scrollable-card-text px-1 pb-3 pt-3"
                        v-show="client.name !== 'Total'"
                      >
                        <v-row class="px-3">
                          <!-- :cols="client.name == 'Total' ? 8 : 6" -->
                          <v-col cols="8" class="text-start pt-1 pb-0">
                            <p
                              class="text-start totalBlack--text"
                              :class="
                                client.name == 'Total'
                                  ? 'f-inter fw-600 fs-16'
                                  : 'f-inter fw-500 fs-12'
                              "
                            >
                              <span v-show="client.name != 'Total'">{{
                                client.name
                              }}</span>
                            </p>
                          </v-col>
                          <!-- :cols="client.name == 'Total' ? 4 : 6" -->
                          <v-col cols="4" class="text-end pt-1 pb-0">
                            <p
                              class="text-end f-inter paidGreen--text fw-400 fs-12"
                            >
                              ${{ client.subTotal.toLocaleString() }}
                            </p>
                          </v-col>
                        </v-row>
                        <v-divider color="greyDivider--text"></v-divider>
                      </v-card-text>
                    </div>

                    <v-card-actions
                      v-for="(clientTotal, indexClientTotal) in clients"
                      :key="indexClientTotal + 'T'"
                      class="justify-space-between px-4"
                      v-show="clientTotal.name == 'Total'"
                    >
                      <span>
                        <span class="totalBlack--text fw-600 fs-16">
                          {{ clientTotal.name }}
                        </span>
                        <v-chip
                          small
                          class="fs-10 fw-400 f-inter paidGreen--text chipReceivable pl-2 pr-2"
                          >{{ clientTotal.totalPercent }}% of total
                          Receivable</v-chip
                        >
                      </span>
                      <span class="totalBlack--text fw-600 fs-16">
                        ${{ clientTotal.subTotal.toLocaleString() }}
                      </span>
                    </v-card-actions>
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                  </v-card>
                </v-col>
                <!-- Vendor details -->
                <v-col :cols="$vuetify.breakpoint.lg ? 6 : 12">
                  <v-card
                    :width="$vuetify.breakpoint.lg ? '470px' : '348px'"
                    height="330px"
                    class="elevation-0"
                    style="border-radius: 6px !important"
                  >
                    <v-card-title
                      class="justify-space-between f-inter fs-14 fw-600 pt-2 pb-2"
                    >
                      <p class="mb-0">Vendor</p>
                      <p class="mb-0">Amount</p>
                    </v-card-title>
                    <v-divider
                      class="mx-1 mb-3"
                      color="greyDivider--text"
                    ></v-divider>

                    <div style="height: 215px">
                      <v-card-text
                        v-for="(vendor, index) in vendors"
                        :key="index + 'V'"
                        class="justify-space-between overflow-hidden scrollable-card-text px-1 pb-3 pt-3"
                        v-show="vendor.name !== 'Total'"
                      >
                        <v-row class="px-3">
                          <v-col cols="8" class="text-start pt-1 pb-0">
                            <p
                              class="text-start totalBlack--text f-inter fw-500 fs-12"
                            >
                              <span v-show="vendor.name != 'Total'">{{
                                vendor.name
                              }}</span>
                            </p>
                          </v-col>
                          <v-col cols="4" class="text-end pt-1 pb-0">
                            <p
                              class="overdueRed--text fw-400 fs-12 text-end f-inter"
                            >
                              ${{ vendor.subTotal.toLocaleString() }}
                            </p>
                          </v-col>
                          <v-divider color="greyDivider--text"></v-divider>
                        </v-row>
                        <v-divider color="greyDivider--text"></v-divider>
                      </v-card-text>
                    </div>
                    <v-card-actions
                      v-for="(vendorTotal, indexVendorTotal) in vendors"
                      :key="indexVendorTotal + 'T'"
                      class="justify-space-between px-4"
                      v-show="vendorTotal.name == 'Total'"
                    >
                      <span>
                        <span class="totalBlack--text fw-600 fs-16">
                          {{ vendorTotal.name }}
                        </span>
                        <v-chip
                          small
                          class="fs-10 fw-400 f-inter overdueRed--text chipColorNewRejectBackground pl-2 pr-2"
                          >{{ vendorTotal.totalPercent }}% of total
                          Payable</v-chip
                        >
                      </span>
                      <span class="totalBlack--text fw-600 fs-16">
                        ${{ vendorTotal.subTotal.toLocaleString() }}
                      </span>
                    </v-card-actions>
                    <v-divider
                      class="mx-1"
                      color="greyDivider--text"
                    ></v-divider>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <!-- **** -->
            <!-- Right section -->
            <v-col :cols="$vuetify.breakpoint.lg ? 4 : 12">
              <p class="f-inter fs=18 fw-600 mb-1">Banks</p>
              <v-card
                height="575px"
                class="rounded pt-4 px-4 elevation-0"
                style="border-radius: 6px !important"
              >
                <v-row v-if="currentWorkspace.isAccountingSoftConnected" dense>
                  <v-col cols="12">
                    <v-skeleton-loader
                      type="card"
                      v-if="bankLoader"
                    ></v-skeleton-loader>

                    <v-card
                      v-if="!bankLoader"
                      color="#7D4CFF"
                      class="whiteColor--text mb-4"
                    >
                      <!-- width="358px" -->
                      <v-card-title class="f-inter fs-14 fw-400 pt-3">
                        Total Balance
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="7" class="text-white">
                            <div class="d-flex flex-column">
                              <span>
                                <p class="fs-22 fw-600 f-inter mb-0">
                                  {{ currency | baseCurrency
                                  }}{{
                                    totalOpening
                                      ? totalOpening.toLocaleString()
                                      : 0
                                  }}
                                </p>
                                <p class="fs-12 fw-400 f-inter">
                                  Total Opening Balance
                                </p>
                              </span>
                              <span>
                                <p class="fs-22 fw-600 f-inter mb-0">
                                  {{ currency | baseCurrency
                                  }}{{
                                    totalClosing
                                      ? totalClosing.toLocaleString()
                                      : 0
                                  }}
                                </p>
                                <p class="fs-12 fw-400 f-inter mb-1">
                                  Bank Balance
                                </p>
                              </span>
                            </div>
                          </v-col>
                          <v-col cols="5" class="">
                            <!-- <highcharts :options="options"></highcharts> -->
                          </v-col>
                        </v-row>
                        <!-- <highcharts :options="options"></highcharts> -->
                      </v-card-text>
                    </v-card>

                    <div class="bank-main mt-5" v-if="!bankLoader">
                      <div v-for="(bank, index) in banks.rows" :key="index">
                        <div
                          v-if="bank.rowType != 'SummaryRow'"
                          class="bank-card px-3 py-2 mb-4 dashboardBlockColor custom-dashboard-border"
                        >
                          <div class="d-flex justify-space-between">
                            <div class="bank-chip d-flex mb-5">
                              <img
                                src="../../assets/dashboard/bank.svg"
                                alt=""
                              />
                              <span
                                class="fw-500 fs-10 f-inter ml-1 ellipsis-text"
                              >
                                {{ bank?.cells[0]?.value }}</span
                              >
                            </div>
                          </div>
                          <v-row>
                            <v-col cols="6">
                              <div class="fw-400 fs-12 f-inter">
                                Closing balance
                              </div>
                              <span class="fs-16 fw-700 f-inter">
                                {{ closingBalance[index]?.value }}
                              </span>
                            </v-col>
                            <v-col cols="6">
                              <div class="fs-12 fw-400 f-inter">
                                <span> Opening balance </span>
                                <span class="fs-16 fw-700">{{
                                  bank?.cells[1]?.value
                                }}</span>
                              </div>
                            </v-col>
                            <div></div>
                          </v-row>
                        </div>
                      </div>
                    </div>

                    <!-- <v-skeleton-loader
                      type="list-item-three-line"
                      v-if="!banks.rows.length"
                    ></v-skeleton-loader> -->
                  </v-col>
                </v-row>
                <div
                  style="height: 624px"
                  class="d-flex justify-center align-center"
                  v-else
                >
                  <v-img
                    contain
                    aspect-ratio="1"
                    src="@/assets/xero-buttons/no-software-connected.png"
                    width="100"
                    max-width="100"
                    max-height="100"
                    height="100"
                  ></v-img>
                  <span class="fs-14 fw-400 text-none">
                    Connect your accounting software to view Banks.
                  </span>
                </div>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-window-item>
    </v-window>
    <!-- </div> -->
    <!-- <no-software-connected v-if="!currentWorkspace?.connected" /> -->
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import ApiService from "../../services/Api";
// import NoSoftwareConnected from "../common/connect-software/NoSoftwareConnected.vue";
import moment from "moment";
export default {
  components: {
    // NoSoftwareConnected,
  },
  data() {
    return {
      tab: "",
      bankLoader: false,
      selectedDate: "",
      dates: [],
      toDate: "",
      menu: false,
      totalReceivablesValue: 0,
      totalPayableValue: 0,
      closingBalance: [],
      overviewTabs: [
        {
          name: "Financial Report",
        },
        {
          name: "FP&A",
        },
        {
          name: "Accounting Reports",
        },
      ],
      payableStatus: [],
      receivableStatus: [],
      clients: [],
      vendors: [],
      banks: [],
      currency: "",
      totalOpening: 0,
      totalClosing: 0,
    };
  },
  async mounted() {
    this.getOpeningBalance();
    await this.fetchTop5("ACCREC");
    await this.fetchTop5("ACCPAY");

    await this.fetchTotalStats("ACCREC");
    await this.fetchTotalStats("ACCPAY");

    this.currency = this.currentWorkspace.organisationDetails.baseCurrency;
  },
  computed: {
    ...mapGetters({
      currentWorkspace: "auth/getCurrentWorkspace",
    }),
    dateRangeText() {
      return this.dates.join(" ~ ");
    },
    currentDate() {
      const date = new Date(); // get the current date and time
      const formattedDate = moment(date).format("YYYY-MM-DD");
      return formattedDate;
    },
  },
  methods: {
    resetDateAndCloseMenu() {
      this.menu = false;
      this.dates = [];
    },
    getBankDetails() {
      this.$refs.menu.save();
      this.dates.sort();
      if (this.dates.length === 0) return;
      if (this.dates.length === 1)
        this.getOpeningBalance(this.dates[0], this.dates[0]);
      if (this.dates.length === 2)
        this.getOpeningBalance(this.dates[0], this.dates[1]);
    },
    // Method to fetch top 5 receivables and payables
    fetchTop5(type) {
      ApiService.GetRequest(
        `report/receivables/${this.currentWorkspace.workspaceId}?type=${type}`
      )
        .then((res) => {
          type == "ACCREC" && (this.clients = res.data);
          type == "ACCPAY" && (this.vendors = res.data);
        })
        .catch((err) => {
          console.log(err.response.data, "ERR");
        });
    },

    fetchTotalStats(type) {
      ApiService.GetRequest(
        `report/total-receivables/${this.currentWorkspace.workspaceId}?type=${type}`
      )
        .then((res) => {
          console.log(res.data, "data");
          if (type == "ACCREC") {
            this.receivableStatus = res.data.stats;
            this.totalReceivablesValue = res.data.totalUnPaid;
          }
          if (type == "ACCPAY") {
            this.payableStatus = res.data.stats;
            this.totalPayableValue = res.data.totalUnPaid;
          }
        })
        .catch((err) => {
          console.log(err.response);
        });
    },

    getOpeningBalance(startDate = null, endDate = null) {
      this.bankLoader = true;
      let dateUrl = null;
      if (startDate && endDate) {
        dateUrl = `?from=${startDate}&to=${endDate}`;
      }
      ApiService.GetRequest(
        `xero/bank-summary/${this.currentWorkspace.workspaceId}${
          dateUrl ? dateUrl : ""
        }`
      )
        .then((res) => {
          this.banks = res.data.body.reports[0].rows[1];
          this.banks.rows.forEach((bank) => {
            if (bank.cells[0].value === "Total") {
              this.totalOpening = parseInt(bank?.cells[1]?.value);
            }
          });
        })
        .catch((err) => {
          this.bankLoader = false;
          console.log(err);
        });
      this.getClosingBalance(startDate, endDate);
    },

    getClosingBalance(startDate = null, endDate = null) {
      ApiService.GetRequest(
        `xero/bank-summary/${this.currentWorkspace.workspaceId}?from=${
          startDate ? startDate : this.currentDate
        }&to=${endDate ? endDate : this.currentDate}`
      )
        .then((res) => {
          let closingBalance = res.data.body.reports[0].rows[1].rows;
          let values = [];
          let rows = [];
          let balance = [];
          let accounts = [];
          let bankAccounts = [];
          let attributes = [];
          let accountIds = [];
          closingBalance.map((val) => {
            values.push(val);
          });
          values.map((bal) => {
            rows.push(bal.cells);
          });
          rows.map((bal) => {
            balance.push(bal[1]);
            accounts.push(bal[0]);
          });
          rows.map((accounts) => {
            bankAccounts.push(accounts[0]);
          });
          bankAccounts.map((attr) => {
            attributes.push(attr.attributes);
          });
          attributes.map((val) => {
            accountIds.push(val);
          });
          this.closingBalance = balance;

          this.totalClosing = parseInt(
            this.closingBalance[this.closingBalance.length - 1].value
          );
          this.bankLoader = false;
        })
        .catch((err) => {
          this.bankLoader = false;
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
:deep .v-tabs-bar.v-item-group > * {
  align-items: center !important;
}
.selectedTabItem {
  background-color: var(--v-lightPurple-base);
  color: white !important;
  border-radius: 6px;
  height: 32px;
}
/* .overview-bg {
  background-color: var(--v-overviewBg-base);
} */
.scrollable-card-text {
  max-height: 280px;
  overflow-y: auto;
}
.bank-main {
  max-height: 340px;
  overflow-y: scroll;
}
.bank-card {
  height: 117px !important;
  width: 100%;
  border: 1px solid var(--v-dashboardBlockBorderColor-base) !important;
  background: var(--v-dashboardBlockColor-base);
  border-radius: 6.28px;
  margin-bottom: 7px;
}

.bank-chip {
  height: 25px;
  width: 94px;
  margin-top: 8px;
  background: var(--v-dashboardBlockBorderColor-base);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
}
.ellipsis-text {
  margin: 0px;
  white-space: nowrap !important;
  width: 60px;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
